<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0 h2back">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> Editar incidencia
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editIncidence">
        <b-form class="mt-2" @submit.prevent="handleSubmit">

          <b-row>
            <b-col sm="6" class="body-info">
              <validation-provider #default="{ errors }" :name="'Incidencia'" rules="required">
                <b-form-group :label="'Incidencia'" label-for="incidence-name">
                  <b-form-input v-model="incidence.name" name="name" :placeholder="'Incidencia'" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-form-group :label="'Descripción'" label-for="incidence-description">
                <quill-editor v-model="incidence.description" />
              </b-form-group>
              <b-form-group :label="'Fecha'" label-for="date">
                <flat-pickr v-model="date" name="date" class="form-control" />
              </b-form-group>
            </b-col>
            <b-col sm="6" class="body-info">
              <!-- client -->
              <b-form-group :label="'Cliente'" label-for="project">
                <b-form-input v-model="client" name="client" disabled />
              </b-form-group>

              <!-- project -->
              <b-form-group :label="'Obra'" label-for="project">
                <b-form-input v-model="project" name="project" disabled />
              </b-form-group>

              <!-- maintenance -->
              <b-form-group :label="'Mantenimiento'" label-for="maintenance">
                <b-form-input v-model="maintenance" name="maintenance" disabled />
              </b-form-group>

              <!-- asset -->
              <b-form-group :label="'Equipo'" label-for="asset" v-if="incidence.asset">
                <b-form-input v-model="incidence.asset.product_name" name="asset" disabled />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="'Imágenes'" label-for="documents">
                <ImageDropzone v-if="incidencePrepare" ref="documents" :files="files" maxFiles="3" />
              </b-form-group>

              <div class="text-right">
                <b-button type="submit" variant="primary" class="mt-2 mr-1 text-uppercase">
                  {{ 'Guardar' }}
                </b-button>
              </div>
            </b-col>

          </b-row>

        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormCheckbox, BInputGroup,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import { config } from "@/shared/app.config"

import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    ImageDropzone,
    BFormCheckbox,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      statusIncidenceTexts: config.statusIncidenceTexts,
      date: '',
      files: [],
      required,

      //fix por si no se ha rellenado
      client: '',
      project: '',
      maintenance: '',

      incidencePrepare: false,
    }
  },
  computed: {
    ...mapGetters({
      incidence: 'incidences/getIncidence',
      selectClients: 'clients/getSelectClients',
      selectMaintenances: "maintenances/getItems",
      selectAssets: "assets/getItems",

    }),
  },
  methods: {
    ...mapActions({
      edit: 'incidences/edit',
      get: 'incidences/getIncidence'
    }),

    handleSubmit() {
      this.$refs.editIncidence.validate().then(success => {
        if (success) {
          const { id } = this.incidence
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, incidence: formData })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end',
          });
        }
      })
    },
    async setData() {

      this.date = TimestampToFlatPickr(this.incidence.date)

      if (this.incidence && this.incidence.documents.length > 0) {
        const aux = this
        this.incidence.documents.forEach((element, index) => {
          const file = {
            name: `File ${index + 1}`,
            url: element.path,
            urlDelete: `/incidences/delete_doc/${aux.incidence.id}/${element.id}`,
          }
          this.files.push(file)
        })
      }

      if (this.incidence.client) {
        this.client = this.incidence.client.name
      }

      if (this.incidence.project) {
        this.project = this.incidence.project.name
      }

      if (this.incidence.maintenance) {
        this.maintenance = this.incidence.maintenance.name
      }

      this.incidencePrepare = true
    },
    createFormData() {
      const data = this.$refs.documents.getFormData('documents')

      data.append('name', this.incidence.name)
      data.append('description', this.incidence.description)

      if (this.date) {
        data.append('date', FlatPickrToTimestamp(this.date))
      }

      return data
    },
  },
  async created() {
    await this.get(this.$route.params.id)
    await this.setData()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

x .icon-trash {
  padding-top: 7px;
}
</style>
